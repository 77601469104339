.pagination
{
    width: 100%;
    text-align: right;
    float: right;
    margin-top: 2em;

    ul {
        list-style-type: none;

        li
        {
            display: inline-block;
            padding-left: 0;

            a, span {
                border: solid 1px _palette(border);
                border-radius: 4px;   
                width: 2.65em;
                height: 2.65em;
                display: inline-block;
                text-align: center;
                line-height: 2.65em;
                color: inherit;
                font-weight: bold;
                margin-left: 0.5em;
            }

            .current {
                text-decoration: none;
                cursor: default;
                border: solid 1px _palette(fg);
                background-color: _palette(fg);
                color: _palette(bg);
            }
        }
    }
}