///
/// Phantom by Pixelarity
/// pixelarity.com @pixelarity
/// License: pixelarity.com/license
///

// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.45s,
		transition:			0.2s
	);

// Size.
	$size: (
		border-radius:		4px,
		border-width:		1px,
		element-height:		3em,
		element-margin:		2em,
		gutter:				2.5em,
		field-gutter:		2em,
		inner:				68em,
		menu:				22em
	);

// Font.
	$font: (
		family:				('Source Sans Pro', Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				300,
		weight-bold:		900,
		weight-bold-alt:	700,
		letter-spacing:		0.35em,
		letter-spacing-alt:	-0.035em
	);

// Palette.
	$palette: (
		bg:					#ffffff,
		bg-accent:			#333333,
		bg-alt:				#f6f6f6,
		fg:					#585858,
		fg-accent:			#ffffff,
		border:				#c9c9c9,
		border-bg:			rgba(144,144,144,0.075),
		accent1:			#f2849e,
		accent2:			#7ecaf6,
		accent3:			#7bd0c1,
		accent4:			#c75b9b,
		accent5:			#ae85ca,
		accent6:			#8499e7,
	);