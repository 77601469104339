///
/// Phantom by Pixelarity
/// pixelarity.com @pixelarity
/// License: pixelarity.com/license
///

/* Wrapper */

	#wrapper {
		> * {
			> .inner {
				$gutter: _size(gutter);

				width: 100%;
				max-width: _size(inner);
				margin: 0 auto;
				padding: 0 $gutter;

				@include breakpoint(small) {
					$gutter: _size(gutter) * 0.5;

					padding: 0 $gutter;
				}

				.properties {
					margin-bottom: 2em;

					@media print {
						font-size: 10px;
					}

					a {
						color: inherit;
						border-bottom-color: inherit;
					}

					b:not(:first-of-type):before {
						content: '|';
						font-weight: normal;
						margin-left: .5em;
						margin-right: .5em;
					}
				}

				.actions {
					text-align: right;
					margin-bottom: 3em;
					text-transform: uppercase;
					letter-spacing: 1px;

					a {
						border: 1px solid _palette(border);
						padding: 0.4em 0.8em;
						margin-left: 0.9em;

						span {
							display: inline-block;
							margin-right: 0.5em;
						}
					}
				}
			}
		}
	}