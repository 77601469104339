.print-footer {
    display: none;
    border-top: 1px solid black;
    font-size: 10px;
}

@media print {
		.print-footer {
            display: block;
        }
        
        .actions {
            display: none;
        }
}