///
/// Phantom by Pixelarity
/// pixelarity.com @pixelarity
/// License: pixelarity.com/license
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease-in-out',
			'color #{_duration(transition)} ease-in-out',
			'box-shadow #{_duration(transition)} ease-in-out'
		));
		background-color: transparent;
		border-radius: _size(border-radius);
		border: 0;
		box-shadow: inset 0 0 0 (_size(border-width) * 2) _palette(fg);
		color: _palette(fg) !important;
		cursor: pointer;
		display: inline-block;
		font-size: 0.8em;
		font-weight: _font(weight-bold);
		height: 3.5em;
		letter-spacing: _font(letter-spacing);
		line-height: 3.45em;
		overflow: hidden;
		padding: 0 1.25em 0 #{1.25em + _font(letter-spacing)};
		text-align: center;
		text-decoration: none;
		text-overflow: ellipsis;
		text-transform: uppercase;
		white-space: nowrap;

		&.icon {
			&:before {
				margin-right: 0.5em;
			}
		}

		&.fit {
			display: block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			width: 100%;
		}

		&:hover {
			color: _palette(accent1) !important;
			box-shadow: inset 0 0 0 (_size(border-width) * 2) _palette(accent1);
		}

		&:active {
			background-color: transparentize(_palette(accent1), 0.9);
		}

		&.small {
			font-size: 0.6em;
		}

		&.big {
			font-size: 1em;
		}

		&.special {
			box-shadow: none;
			background-color: _palette(fg);
			color: _palette(bg) !important;

			&:hover {
				background-color: _palette(accent1);
			}

			&:active {
				background-color: darken(_palette(accent1), 8);
			}
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}
	}