.post {
    h1:first-of-type {
    margin-bottom: .2em;
    }   
    
    img:not(.gallery) {
        width: auto !important;
        max-width: 80vw !important;
        height: auto;
        margin-right: auto;
        margin-left: auto;
        display: block;
    }
}