///
/// Phantom by Pixelarity
/// pixelarity.com @pixelarity
/// License: pixelarity.com/license
///

/* Footer */

	#footer {
		$gutter: _size(gutter);

		@include padding(5em, 0, (0, 0, 3em, 0));
		background-color: _palette(bg-alt);

		> .inner {
			@include vendor('display', 'flex');
			@include vendor('flex-wrap', 'wrap');
			@include vendor('flex-direction', 'row');

			> * > :last-child {
				margin-bottom: 0;
			}

			section:nth-child(1) {
				width: calc(66% - #{$gutter});
				margin-right: $gutter;
			}

			section:nth-child(2) {
				width: calc(33% - #{$gutter});
				margin-left: $gutter;
			}

			.copyright {
				width: 100%;
				padding: 0;
				margin-top: 5em;
				list-style: none;
				font-size: 0.8em;
				color: transparentize(_palette(fg), 0.5);

				a {
					color: inherit;
				}

				li {
					display: inline-block;
					border-left: solid 1px transparentize(_palette(fg), 0.85);
					line-height: 1;
					padding: 0 0 0 1em;
					margin: 0 0 0 1em;

					&:first-child {
						border-left: 0;
						padding-left: 0;
						margin-left: 0;
					}
				}
			}
		}

		@include breakpoint(large) {
			$gutter: _size(gutter) * 0.5;

			@include padding(5em, 0);

			> .inner {
				section:nth-child(1) {
					width: calc(66% - #{$gutter});
					margin-right: $gutter;
				}

				section:nth-child(2) {
					width: calc(33% - #{$gutter});
					margin-left: $gutter;
				}
			}
		}

		@include breakpoint(medium) {
			$gutter: _size(gutter);

			> .inner {
				section:nth-child(1) {
					width: 66%;
					margin-right: 0;
				}

				section:nth-child(2) {
					width: calc(33% - #{$gutter});
					margin-left: $gutter;
				}
			}
		}

		@include breakpoint(small) {
			@include padding(3em, 0);

			> .inner {
				@include vendor('flex-direction', 'column');

				section:nth-child(1) {
					width: 100%;
					margin-right: 0;
					margin: 3em 0 0 0;
				}

				section:nth-child(2) {
					@include vendor('order', '-1');
					width: 100%;
					margin-left: 0;
				}

				.copyright {
					margin-top: 3em;
				}
			}
		}

		@include breakpoint(xsmall) {
			> .inner {
				.copyright {
					margin-top: 3em;

					li {
						border-left: 0;
						padding-left: 0;
						margin: 0.75em 0 0 0;
						display: block;
						line-height: inherit;

						&:first-child {
							margin-top: 0;
						}
					}
				}
			}
		}
	}