@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import 'font-awesome.min.css';
@import url('http://fonts.googleapis.com/css?family=Source+Sans+Pro:300,700,900');

/*
	Phantom by Pixelarity
	pixelarity.com @pixelarity
	License: pixelarity.com/license
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
		xxsmall: '(max-width: 360px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: (
			gutters: 2em
		),
		breakpoints: (
			medium: (
				grid: (
					gutters: 1.5em
				)
			),
			small: (
				grid: (
					gutters: 1em
				)
			)
		)
	));

// Base.

	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/section';
	@import 'components/icon';
	@import 'components/list';
	@import 'components/form';
	@import 'components/box';
	@import 'components/image';
	@import 'components/table';
	@import 'components/button';
	@import 'components/tiles';
	@import 'components/pagination';
	@import 'components/video';

// Layout.

	@import 'layout/header';
	@import 'layout/menu';
	@import 'layout/main';
	@import 'layout/footer';
	@import 'layout/search';
	@import 'layout/wrapper';
	@import 'layout/post';
	@import 'layout/print';