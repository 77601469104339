/* Search */

	#search {
		$gutter: _size(gutter);
		margin-bottom: 3em;

		@include padding(5em, 0, (0, 0, 3em, 0));
		background-color: _palette(bg-alt);

		> .inner {
			@include vendor('display', 'flex');
			@include vendor('flex-wrap', 'wrap');
			@include vendor('flex-direction', 'row');

			section {
				width: 100%;
			}

			> * > :last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint(large) {
			$gutter: _size(gutter) * 0.5;

			@include padding(5em, 0);
		}

		@include breakpoint(medium) {
			$gutter: _size(gutter);
		}

		@include breakpoint(small) {
			@include padding(3em, 0);

			> .inner {
				@include vendor('flex-direction', 'column');
			}
		}

		@include breakpoint(xsmall) {
			> .inner {
				.copyright {
					margin-top: 3em;

					li {
						border-left: 0;
						padding-left: 0;
						margin: 0.75em 0 0 0;
						display: block;
						line-height: inherit;

						&:first-child {
							margin-top: 0;
						}
					}
				}
			}
		}
	}