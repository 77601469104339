///
/// Phantom by Pixelarity
/// pixelarity.com @pixelarity
/// License: pixelarity.com/license
///

/* Basic */

	// MSIE: Required for IEMobile.
		@-ms-viewport {
			width: device-width;
		}

	// MSIE: Prevents scrollbar from overlapping content.
		body {
			-ms-overflow-style: scrollbar;
		}

	// Ensures page width is always >=320px.
		@include breakpoint(xsmall) {
			html, body {
				min-width: 320px;
			}
		}

	body {
		background: _palette(bg);

		// Prevents animation/transition "flicker" on page load.
		// Automatically added/removed by js/main.js.
			&.is-loading {
				*, *:before, *:after {
					@include vendor('animation', 'none !important');
					@include vendor('transition', 'none !important');
				}
			}

	}