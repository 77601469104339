///
/// Phantom by Pixelarity
/// pixelarity.com @pixelarity
/// License: pixelarity.com/license
///

/* Form */

	form {
		margin: (_size(element-margin) * -0.5) 0 _size(element-margin) 0;

		&:after {
			content: '';
			display: block;
			clear: both;
			height: 1px;
		}

		.field {
			position: relative;
			float: left;
			margin: 0 0 (_size(element-margin) * 0.65) 0;
			vertical-align: top;
			width: 100%;
		}

		.half {
			width: calc(50% + #{_size(field-gutter) * 0.5}) !important;
			padding-left: _size(field-gutter);
		}

		.half.first {
			width: calc(50% - #{_size(field-gutter) * 0.5}) !important;
			padding-left: 0;
		}

		.third {
			width: calc(33% + #{_size(field-gutter) * 0.5}) !important;
			padding-left: _size(field-gutter);
		}

		.third.first {
			width: calc(33% - 2 * #{_size(field-gutter) * 0.5}) !important;
			padding-left: 0;
		}

		.fourth {
			width: calc(25% + #{_size(field-gutter) * 0.5}) !important;
			padding-left: _size(field-gutter);
		}

		.fourth.first {
			width: calc(25% - 3 * #{_size(field-gutter) * 0.5}) !important;
			padding-left: 0;
		}

		:last-child {
			margin-bottom: 0;
		}

		.half:nth-last-child(2) {
			margin-bottom: 0;
		}

		.actions {
			position: relative;
			clear: both;
			padding-top: (_size(element-margin) * 0.5);
		}
		
		@include breakpoint(medium) {
			.fourth {
				width: calc(50% + #{_size(field-gutter) * 0.5}) !important;
				padding-left: _size(field-gutter);
			}

			.fourth:nth-child(2n + 1) {
				width: calc(50% - #{_size(field-gutter) * 0.5}) !important;
				padding-left: 0;
			}
		}

		@include breakpoint(small) {
			.half, .fourth {
				width: 100% !important;
				padding-left: 0;
			}

			.half.first, .fourth.first {
				width: 100% !important;
				padding-left: 0;
			}
			
			.fourth:nth-child(2n + 1) {
				width: 100% !important;
				padding-left: 0;
			}
		}
	}

	label {
		display: block;
		font-size: 0.9em;
		font-weight: _font(weight-bold);
		margin: 0 0 (_size(element-margin) * 0.5) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		background-color: transparent;
		border: none;
		border-radius: 0;
		border-bottom: solid _size(border-width) _palette(border);
		color: inherit;
		display: block;
		outline: 0;
		padding: 0;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}

		&:focus {
			border-bottom-color: _palette(accent1);
			box-shadow: inset 0 -1px 0 0 _palette(accent1);
		}
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			color: _palette(border);
			content: '\f078';
			display: block;
			height: _size(element-height);
			line-height: _size(element-height);
			pointer-events: none;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: _size(element-height);
		}

		select::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0;
		min-height: (_size(element-height) * 1.25);
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;

		& + label {
			@include icon;
			color: _palette(fg);
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: _font(weight);
			padding-left: (_size(element-height) * 0.6) + 0.75em;
			padding-right: 0.75em;
			position: relative;

			&:before {
				border-radius: _size(border-radius);
				border: solid _size(border-width) _palette(border);
				content: '';
				display: inline-block;
				height: (_size(element-height) * 0.6);
				left: 0;
				line-height: (_size(element-height) * 0.575);
				position: absolute;
				text-align: center;
				top: 0;
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				background: _palette(fg);
				border-color: _palette(fg);
				color: _palette(bg);
				content: '\f00c';
			}
		}

		&:focus + label {
			&:before {
				border-color: _palette(accent1);
				box-shadow: 0 0 0 _size(border-width) _palette(accent1);
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: _size(border-radius);
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	.select-wrapper {
		&:before {
			content: 'a';
			position: absolute;
			left: 0;
			right: auto;
			width: 1em;
			color: black;
			content: '\f0d7';
		}

		select {
			padding-left: 2em;
		}
	}